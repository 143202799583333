/****   request.js   ****/
// 导入axios
import axios from "axios";
import qs from 'qs'
//1. 创建新的axios实例，
const service = axios.create({
  // 超时时间 单位是ms，这里设置了3s的超时时间
  timeout: 60*1000,
  headers: {
    get: {
      "Content-Type": "application/x-www-form-urlencoded;charset=utf-8",
      // 在开发中，一般还需要单点登录或者其他功能的通用请求头，可以一并配置进来
    },
    post: {
      // "Content-Type": "application/x-www-form-urlencoded",
      // "Content-Type": "application/x-www-form-urlencoded;charset=utf-8",
      "Content-Type": "application/json;",
      // 在开发中，一般还需要单点登录或者其他功能的通用请求头，可以一并配置进来
    },
  },
});
// 2.请求拦截器
service.interceptors.request.use(
  (config) => {
    if (process.env.NODE_ENV == "production") {
      if (config.url != "http://www.nmc.cn/rest/weather") {
        config.url = requestUrl + config.url;
    }
    
    }
    // config.headers['Content-Type'] = "application/x-www-form-urlencoded"
    // config.headers['Content-Type'] = "application/x-www-form-urlencoded"
    // config.data = qs.stringify(config.data)
    //发请求前做的一些处理，数据转化，配置请求头，设置token,设置loading等，根据需求去添加
    // config.data = JSON.stringify(config.data); //数据转化,也可以使用qs转换
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

// 3.响应拦截器
service.interceptors.response.use(
  (response) => {
    //接收到响应数据并成功后的一些共有的处理，关闭loading等

    return response.data;
  },
  (error) => {
    /***** 接收到异常响应的处理开始 *****/
    if (error && error.response) {
      // 1.公共错误处理
      // 2.根据响应码具体处理
      switch (error.response.status) {
        case 400:
          error.message = "错误请求";
          break;
        case 401:
          error.message = "请重新登录";
          break;
        case 403:
          error.message = "拒绝访问";
          break;
        case 404:
          error.message = "请求错误,未找到该资源";
          // window.location.href = "/NotFound";
          break;
        case 405:
          error.message = "请求方法未允许";
          break;
        case 408:
          error.message = "请求超时";
          break;
        case 500:
          error.message = "服务器端出错";
          break;
        default:
          error.message = `连接错误${error.response.status}`;
      }
    } else {
      // 超时处理
      if (JSON.stringify(error).includes("timeout")) {
        // Message.error("服务器响应超时，请刷新当前页");
      }
      error.message = "连接服务器失败";
    }

    // Message.error(error.message);
    /***** 处理结束 *****/
    //如果不需要错误处理，以上的处理过程都可省略
    return Promise.resolve(error.response.data);
  }
);
//4.导入文件
export default service;
