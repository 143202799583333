
<template>
  <van-overlay :show="show" :custom-style="{backgroundColor: 'rgba(0,0,0,.8)',zIndex:99999}">
    <van-loading class="sp-loading" type="spinner" color="#1989fa" />
  </van-overlay>
</template>

<script>
export default {
  name: "Loading",
  props: {
    show: {
      type: Boolean
    },
    text: {
      type: String,
      default: ""
    }
  }
};
</script>

<style lang="scss" scoped>
.sp-loading{
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -200%);

}
</style>