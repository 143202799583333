import './utils/plugin.js' // 先引入 vant 组件库 这样做组件库的css样式可以被覆盖
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './utils/setConfig.js'
import './style/global.scss'
import loading from "./components/myloading/index.js"; // 引入loading
Vue.config.productionTip = false
Vue.use(loading); // 全局使用loading
Vue.use(require('vue-wechat-title')); // 微信标题插件

new Vue({ router, store,  render: h => h(App), }).$mount('#app');
