
import request from "@/utils/request.js";



// 积水点查询
// export function getwather(params) {
//   return request({
//     url: obj.proxys + "/api/Pondpoint/getwather",
//     method: "GET",
//     params
//   });
// }

// POST /api/Wechat/AddFlowInfo
// 微信页面访问+1
export function AddFlowInfo(params) {
  return request({
    url: "/api/Wechat/AddFlowInfo",
    method: "post",
    params
  });
}

// POST /api/Wechat/GetTicks
// 获取微信接口权限
export function GetTicks(params) {
  return request({
    url: "/api/Wechat/GetTicks",
    method: "post",
    data: params,
    params
  });
}

// http://qqhrqxj.com/api/Wechat/GetQxbw?type=气象谚语
export function GetQxbw(params) {
  return request({
    url: "/api/Wechat/GetQxbw",
    method: "post",
    data:params,
    params
  });
}