
/** 使用组件前需要先行引入组件，否则会报错*/

import Vue from 'vue'
 
import { Button, DropdownMenu, DropdownItem,  Checkbox, CheckboxGroup, Search, Tab, Tabs, DatetimePicker, Popup, Picker, Col,cell, Row, icon, Overlay, Dialog, popover, Form, Field, Uploader,ImagePreview,RadioGroup,Radio,Loading, NavBar, NumberKeyboard,Swipe,SwipeItem      } from 'vant';

const list = [
  Button, DropdownMenu, DropdownItem, Search,  Checkbox, CheckboxGroup, Tab, Tabs, DatetimePicker, Popup, Picker, Col,cell, Row, icon, Overlay, popover,Dialog,  Form, Field,  Uploader, ImagePreview,RadioGroup,Radio,Loading, NavBar ,NumberKeyboard,Swipe,SwipeItem   
];

list.forEach(it=>{
  Vue.use(it);
})