import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

export default new Vuex.Store({

  state: {
    // userInfo: null
  },

  mutations: {
    // 增加一个带参数的mutations方法，并且官方建议payload为一个对象
    // setNumberIsWhat(state, payload) {
    //   state.userInfo = payload;
    //   sessionStorage.setItem("userData", JSON.stringify(state.userInfo));
    // },
    
    // updateState(state){
    //   const data = JSON.parse(sessionStorage.getItem("userData"));
    //   state.userInfo = data;
    // }

  },

  actions: {

  },

  modules: {

  }
})
