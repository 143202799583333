<template>
  <div id="app">
    <!--控制微信title-->
    <router-view v-wechat-title="$route.meta.title" />
  <template v-if="showMenu">
    <!-- <div style="height: 50px;"></div> -->
    <van-button style="position: fixed; bottom: 0;" size="mini" plain type="primary" @click="showPage = true;">打开目录
    </van-button>
    <van-popup v-model="showPage" position="bottom" style="height:40%;">
      <van-button style="margin-top: 5px;" plain hairline block type="primary" v-for="item of listPage" :key="item.name"
        @click="$router.push(item.path)">
        {{ item.meta.title }}
      </van-button>
    </van-popup>
  </template>


  </div>
</template>

<script>

export default {
  name: 'App',
  data() {
    return {
      showPage: false,
      listPage: this.$router.options.routes,
      showMenu: process.env.NODE_ENV === "development"
    }
  },
  created(){
    if(process.env.NODE_ENV !== "production"){
      window.imgUrl = ''
    }
    console.log(window.imgUrl)
  }
}
</script>

<style lang="scss">
/**
* 注意：内联样式不能用px 作为单位，否则会出现样式不兼容，响应式不生效问题
*       px在页面生成时 已经被重写计算为 rem单位
*/
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased; // CSS3 的一个属性，这个属性可以使页面上的字体抗锯齿,使用后字体看起来会更清晰舒服。
  -moz-osx-font-smoothing: grayscale;
  font-size: 16px;
  color: #fff;
  height: 100%;
}

html,
body {
  height: 100%;
}
/**高德地图logo */
.amap-copyright{
  display: none !important;
}
/**loading 样式 */
.wrapper{
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50% -50%);
}
@import './style/index.scss';
</style>
