import Vue from 'vue'
import VueRouter from 'vue-router'
import request from "@/utils/request.js";
import { AddFlowInfo } from "@/api/index.js";

Vue.use(VueRouter)

const routes = [
  {
    path: '/metar',
    name: 'metar',
    component:()=> import('@/views/Metar/metar.vue'),
    meta: {
      title: '天气实况'
    },
  },
  {
    path: '/',
    name: 'index',
    component:()=> import('@/views/index.vue'),
    meta: {
      title: '邯郸市'
    },
  },
  {
    path: '/copy',
    name: 'indexcopy',
    component:()=> import('@/views/index copy.vue'),
    meta: {
      title: '邯郸市'
    },
  },
  // {
  //   path: '/metar',
  //   name: 'metar1',
  //   component:()=> import('@/views/Metar/metar1.vue'),
  //   meta: {
  //     title: '天气实况'
  //   },
  // },
  {
    path: '/airQuality',
    name: 'airQuality',
    component:()=> import('../views/AirQuality/airQuality.vue'),
    meta: {
      title: '空气质量'
    },
  },
  {
    path: '/radarChart',
    name: 'radarChart',
    component:()=> import('@/views/RadarChart/radarChart.vue'),
    meta: {
      title: '雷达云图'
    },
  },
  {
    path: '/radarChart1',
    name: 'radarChart1',
    component:()=> import('@/views/RadarChart/radarChart1.vue'),
    meta: {
      title: '测试雷达云图'
    },
  },
  {
    path: '/statistics',
    name: 'statistics',
    component:()=> import('@/views/Statistics/statistics.vue'),
    meta: {
      title: '数据统计'
    },
  },
  {
    path: '/zyWeather',
    name: 'zyWeather',
    component:()=> import('@/views/ZYweather/zyWeather.vue'),
    meta: {
      title: '农业气象'
    },
  },
  {
    path: '/dlServe',
    name: 'dlServe',
    component:()=> import('@/views/ZYweather/dlServe.vue'),
    meta: {
      title: '电力服务'
    },
  },  
  {
    path: '/ayhServe',
    name: 'ayhServe',
    component:()=> import('@/views/ZYweather/ayhServe.vue'),
    meta: {
      title: '省运会气象服务'
    },
  },  
  {
    path: '/nlServe',
    name: 'nlServe',
    component:()=> import('@/views/ZYweather/nlServe.vue'),
    meta: {
      title: '内涝服务'
    },
  },  
  // {
  //   path: '/TianQiYuBao',
  //   name: 'TianQiYuBao',
  //   component:()=> import('@/views/TianQiYuBao/TianQiYuBao.vue'),
  //   meta: {
  //     title: '天气预报1'
  //   },
  // },
  {
    path: '/startPages',
    name: 'startPages',
    component:()=> import('@/views/TianQiYuBao/startPages.vue'),
    meta: {
      title: '天气预报'
    },
  },
  {
    path: '/startPagescopy',
    name: 'startPagescopy',
    component:()=> import('@/views/TianQiYuBao copy/startPages.vue'),
    meta: {
      title: '天气预报'
    },
  },
  // {
  //   path: '/TianQiYuBao1',
  //   name: 'TianQiYuBao1',
  //   component:()=> import('@/views/TianQiYuBao/TianQiYuBao2.vue'),
  //   meta: {
  //     title: '天气预报1'
  //   },
  // },
  {
    path: '/Subscribe',
    name: 'Subscribe',
    component:()=> import('@/views/Subscribe/Subscribe.vue'),
    meta: {
      title: '个人订阅'
    },
  },
  {
    path: '/Travel',
    name: 'Travel',
    component:()=> import('@/views/Travel/Travel.vue'),
    meta: {
      title: '旅游气象'
    },
  },
  {
    path: '/TrafficQiX',
    name: 'TrafficQiX',
    component:()=> import('@/views/TrafficQiX/TrafficQiX.vue'),
    meta: {
      title: '交通气象'
    },
  },
  {
    path: '/Newspaper',
    name: 'Newspaper',
    component:()=> import('@/views/Newspaper/Newspaper.vue'),
    meta: {
      title: '气象专报'
    },
  },
  {
    path: '/ZaiQReport',
    name: 'ZaiQReport',
    component:()=> import('@/views/ZaiQReport/ZaiQReport.vue'),
    meta: {
      title: '灾情上报'
    },
  },
  {
    path: '/proofApplication',
    name: 'proofApplication',
    component:()=> import('@/views/ZaiQReport/proofApplication.vue'),
    meta: {
      title: '气象证明'
    },
  },
  {
    path: '/wgyb',
    name: 'wgyb',
    component:()=> import('@/views/WGYB/wgyb.vue'),
    meta: {
      title: '智能网格预报'
    },
  },
  {
    path: '/tqkb',
    name: 'news',
    component:()=> import('@/views/tqkb/news.vue'),
    meta: {
      title: '天气快报'
    },
  },
  {
    path: '/Reset',
    name: 'Reset',
    component:()=> import('@/views/Reset.vue'),
    meta: {
      title: '请在微信中打开'
    },
  },
  {
    path: '/GZH',
    name: 'GZH',
    component:()=> import('@/views/GZH.vue'),
    meta: {
      title: '邯郸市气象矩阵'
    },
  },
  {
    path: '/QXKP',
    name: 'QXKP',
    component:()=> import('@/views/QXKP.vue'),
    meta: {
      title: '气象科普'
    },
  },
  {
    path: '/power',
    name: 'power',
    component:()=> import('@/views/power.vue'),
    meta: {
      title: '提示'
    },
  },
  {
    path: '/ZaiHaiYuJing',
    name: 'ZaiHaiYuJing',
    component:()=> import('@/views/ZaiHaiYuJing/ZaiHaiYuJing.vue'),
    meta: {
      title: '灾害预警'
    },
  },{
    path: '/nongyelogin',
    name: 'nongyelogin',
    component:()=> import('@/views/nongye/login.vue'),
    meta: {
      title: '邯郸农业平台'
    },
  },
  {
    path: '/nongye',
    name: 'nongye',
    component:()=> import('@/views/nongye/index.vue'),
    meta: {
      title: '邯郸农业平台'
    },
  },
  {
    path: '/station',
    name: 'station',
    component:()=> import('@/views/nongye/station.vue'),
    meta: {
      title: '小气候站'
    },
  },
  {
    path: '/cailiao',
    name: 'cailiao',
    component:()=> import('@/views/nongye/baobiao.vue'),
    meta: {
      title: '服务材料查看'
    },
  },
  {
    path: '/tjdc',
    name: 'tjdc',
    component:()=> import('@/views/nongye/tjdc.vue'),
    meta: {
      title: '田间调查'
    },
  },
  {
    path: '/his',
    name: 'his',
    component:()=> import('@/views/nongye/his.vue'),
    meta: {
      title: '田间调查'
    },
  },
  {
    path: '/serve',
    name: 'serve',
    component:()=> import('@/views/serve/serve.vue'),
    meta: {
      title: '气象服务'
    },
  },
  {
    path: '/serve1',
    name: 'serve1',
    component:()=> import('@/views/serve/serve1.vue'),
    meta: {
      title: '气象服务'
    },
  },
]

const router = new VueRouter({
  // mode: 'history',
  // base: process.env.BASE_URL,
  routes
})

// GET /api/Admin/GetWxRoleMList
// 获取微信所有模块
function GetWxRoleMList(params) {
  return request({
    url: "/api/Admin/GetWxRoleMList",
    method: "get",
    params,
  });
}

function isGo(){
    //是否是微信浏览器
    if (/(micromessenger)/i.test(navigator.userAgent)) { 
      //是否电脑微信或者微信开发者工具
        if(/(WindowsWechat)/i.test(navigator.userAgent) || /(wechatdevtools)/i.test(navigator.userAgent)){
          // alert('电脑微信或者微信开发者工具')
          console.log('电脑微信或者微信开发者工具')
        }else{
          //手机微信打开的浏览器
        // console.log('手机微信')
        }
        return true
    } else {
        // console.log(('其他浏览器'))
        if(process.env.NODE_ENV === "development") return true
        return false
    }
}

router.beforeEach((to, from, next) => {
  // 获取系统所有模块 // 访问模块加+1
  console.log(to.fullPath)
  if(!isGo() && to.fullPath!=='/Reset') return next({ path: '/Reset'});
  else next();
  GetWxRoleMList().then(res=>{
    res.data.forEach(item=>{
      item.sublevel.forEach(async it=>{
        if(it.Title === to.meta.title){
          console.log(it)
          await AddFlowInfo({m_Id:it.Id})
        }
      })
    })
  })
});

export default router
